<template>
    <v-row>
        <v-col
            v-for="(item, index) in items"
            :key="index"
            class="d-flex child-flex"
            cols="auto"
        >
        <!-- <div @mouseover="mouseover(item.ids)" @mouseout="mouseout"> -->
        <div>
            <!-- <v-img
                :src="getImgUrl2(curImgId)"
                :lazy-src="getImgUrl2(curImgId)"
                aspect-ratio="1"
                class="grey lighten-3"
                contain
                width="125"
                height="125"
                v-if="curImg.id == item.ids[0]"
            >
            </v-img> -->
            <v-tooltip top
                color="black"
                nudge-bottom="50"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-card>
                        <v-img
                            :src="getImgUrl2(item.rid)"
                            :lazy-src="getImgUrl2(item.rid)"
                            aspect-ratio="1"
                            class="grey lighten-3 hand-over"
                            contain
                            width="125"
                            height="125"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="clickCallback(item)"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <!-- <v-btn
                            class="btn-del"
                            fab
                            elevation="2"
                            x-small
                            color="white"
                            width="25"
                            height="25"
                            @click.stop="delCallback(item.rid)"
                        >
                            <v-icon color="grey darken-1"> mdi-close </v-icon>
                        </v-btn> -->
                    </v-card>
                </template>
                <div>{{ item.name_cn }}</div>
                <div>{{ item.name_en }}</div>
            </v-tooltip>
        </div>
        </v-col>
    </v-row>
</template>

<script>
import { getImgUrl2 } from "@/utils/util";

export default {
    name: 'pictureList',
    props: [
        'items'
    ],
    computed: {
        curImgId() {
            return this.curImg.imgs[this.curImg.imgIdx]
        }
    },
    data() {
        return {
            curImg: {
                id: '',
                imgs: [],
                imgIdx: 0,
            },
            intervalAutoSwitch: null,
        };
    },
    methods: {
        getImgUrl2,
        mouseover(itemId) {
            this.curImg.id = itemId[0]
            this.curImg.imgIdx = 0
            this.curImg.imgs = itemId

            if (this.curImg.imgs.length > 1) {
                this.curImg.imgIdx = 1
                this.intervalAutoSwitch = setInterval(this.autoSwitchImg, 600);
            }
        },
        mouseout() {
            this.curImg.id = ''
            this.curImg.imgIdx = 0
            this.curImg.imgs = []
            clearInterval(this.intervalAutoSwitch);
        },
        autoSwitchImg() {
            if (this.curImg.imgs.length > 1) {
                this.curImg.imgIdx++;
                if (this.curImg.imgIdx >= this.curImg.imgs.length) {
                    this.curImg.imgIdx = 0;
                }
            }
        },
        clickCallback(res) {
            this.$emit("clickCallback", res)
        },
        delCallback(rid) {
            this.$emit("delCallback", rid)
        }
    }
}
</script>

<style lang="scss" scoped>
.hand-over {
    cursor: pointer;
}
.btn-del {
    position: absolute;
    top: -8px;
    right: -25px;
    &:hover {
        background-color: #eee !important;
    }
}
</style>
