<template>
    <v-container fluid class="px-8 mb-8">
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="blue darken-2"
                    class="font-weight-bold text-body-1 mr-4"
                    @click.stop="openEditImgDlg()"
                    v-if="isPrivate"
                >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    增加图片
                </v-btn>
                <!-- <v-btn
                    tile
                    dark
                    color="indigo darken-1"
                    class="font-weight-bold text-body-1"
                >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    增加多图资源
                </v-btn> -->
            </v-col>

            <v-col class="d-flex justify-center" >
                <v-btn
                    class="btn-font"
                    style="margin: 0;"
                    tile
                    dark
                    depressed
                    color="indigo"
                    :outlined="!isPrivate"
                    @click="toggleRes"
                >
                    自有资源
                </v-btn>
                <v-btn
                    class="btn-font"
                    style="margin: 0;"
                    tile
                    dark
                    depressed
                    color="indigo"
                    :outlined="isPrivate"
                    @click="toggleRes"
                >
                    公共资源
                </v-btn>
            </v-col>

            <v-col class="d-flex justify-end" >
                <v-text-field
                    label="搜索"
                    hide-details
                    filled
                    rounded
                    dense
                    single-line
                    style="max-width: 240px"
                    append-icon="mdi-magnify"
                    @click:append="search"
                >
                </v-text-field>
            </v-col>
        </v-row>

        <!-- 类型标签 -->
        <v-row justify="space-around">
            <v-col
                cols="12"
                md="12"
            >
            <v-sheet
                elevation="0"
            >
                <v-chip-group
                    v-model="index"
                    mandatory
                    active-class="primary--text"
                    @change="categoryChange"
                >
                    <v-chip
                        v-for="item in items"
                        :key="item.name"
                        dark
                    >
                        {{ item.name }}
                    </v-chip>
                </v-chip-group>
            </v-sheet>
            </v-col>
        </v-row>

        <PictureList 
            :items="resList"
            @clickCallback="openEditImgDlg"
            @delCallback="openDelImgConfirmDlg"
        />
        <div v-if="resList.length == 0">
            <div class="empty-list">
                <img src="@/assets/images/ide/empty.svg">
                <div class="empty-text">暂无图片资源</div>
            </div>
        </div>

        <EditImgDlg ref="editDlg" @submit="submitResource" />

        <ConfirmDlg
            ref="delConfirmDlg"
            title="删除图片"
            text="确认 删除 当前图片么？"
            keyword=" 删除 "
            @confirm="delImgConfirm"
        />
       
    </v-container>
</template>

<script>
import Vue from 'vue';
import EditImgDlg from "@/views/components/dialog/EditImgDlg";
import ConfirmDlg from "@/views/components/dialog/ConfirmDlg";
import PictureList from "@/views/components/module/PictureList";
import { getResources2, editResource, delResource } from "@/api/admin";

export default {
    name: "Picture",
    data() {
        return {
            isPrivate: true,
            index: '',
            items: [
                { name: '人物', category: 0 },
                { name: '动物', category: 1 },
                { name: '植物', category: 7 },
                { name: '食物', category: 8 },
                { name: '道具', category: 2 },
                { name: '字母', category: 9 },
                { name: '背景（竖屏）', category: 3 },
                { name: '背景（横屏）', category: 4 },
            ],
            resList: [],
        };
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
        toggleRes() {
            this.isPrivate = !this.isPrivate
            this.loadData(this.items[this.index].category)
        },
        categoryChange() {
            // console.log(this.index)
            this.loadData(this.items[this.index].category)
        },
        loadData(category) {
            let scope = this.isPrivate ? 'PRIVATE' : 'PUBLIC'

            getResources2({scope, category}).then(response => {
                // console.log(response.data)
                this.resList = response.data;
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        // 对话框
        openEditImgDlg(res = null) {
            this.$refs.editDlg.openDlg(res, this.items[this.index].category);
        },
        // 表单对话框确认callback
        submitResource(newRes) {
            // console.log(newRes)
            editResource({
                rid: newRes.rid,
                name_cn: newRes.name_cn,
                name_en: newRes.name_en,
                res_type: newRes.res_type,
                ext: newRes.ext,
                category: newRes.category,
                group_ids: newRes.group_ids,
                group_index: newRes.group_index,

            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "创建图片成功",
                    });

                    // 将新增图片添加到当前页面中
                    let result = response.data;
                    if (newRes.isCreate) {
                        this.resList.unshift(result)
                    } else {
                        for (let i in this.resList) {
                            if (this.resList[i].rid == result.rid) {
                                if (this.resList[i].category == result.category) {
                                    Vue.set(this.resList, i, result)
                                } else {
                                    this.resList.splice(i, 1)
                                    // Vue.delete(this.resList, i)
                                }
                                break
                            }
                        }
                    }

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "创建图片失败",
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        // 删除图片确认对话框
        openDelImgConfirmDlg(id) {
            this.$refs.delConfirmDlg.openDlg(id);
        },
        // 确认对话框confirm callback
        delImgConfirm(id) {
            // console.log(id)
            delResource(id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除图片成功",
                    });

                    // 将图片删除
                    for (let i in this.resList) {
                        if (this.resList[i].rid == id) {
                            this.resList.splice(i, 1)
                            // Vue.delete(this.resList, i)
                            break
                        }
                    }

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除图片失败",
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },
        search() {
            // console.log('search')
        }
    },
    components: {
        EditImgDlg,
        ConfirmDlg,
        PictureList,
    },
};
</script>

<style lang="scss" scoped>
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
        display: flex;
        justify-content: flex-start;
    }
}

.empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    img {
        height: 45px;
        margin-bottom: 20px;
    }
    .empty-text {
        color: #999;
        font-size: 14px;
    }
}
</style>
