<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="600">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">增加图片</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row d-flex align="center">
                    <v-col cols="12" md="4" offset="1">
                        <v-img
                            :src="previewImg"
                            height="150"
                            width="150"
                            contain
                        ></v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                        <form class="">
                            <v-text-field
                                label="中文名称"
                                v-model="name_cn"
                                required
                                :error-messages="nameCnErrors"
                                :counter="20"
                                @input="$v.name_cn.$touch()"
                                @blur="$v.name_cn.$touch()"
                            ></v-text-field>

                            <v-text-field
                                label="英文名称"
                                v-model="name_en"
                                required
                                :error-messages="nameEnErrors"
                                :counter="20"
                                @input="$v.name_en.$touch()"
                                @blur="$v.name_en.$touch()"
                            ></v-text-field>

                            <v-select
                                v-model="select"
                                :items="items"
                                item-text="name"
                                item-value="categoy"
                                label="类别"
                                @change="selected"
                                return-object
                            ></v-select>

                            <v-file-input
                                v-model="file"
                                required
                                :rules="rules"
                                accept="image/png, image/jpeg, image/jpg, image/svg, image/*"
                                label="上传图片"
                                small-chips
                                @change="uploadFile"
                                v-if="isCreate"
                            ></v-file-input>
                        </form>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import qiniu from '@/utils/qiniu.js'
import { getImgUrl2 } from "@/utils/util.js"

export default {
    name: "EditTopicGroupDlg",

    mixins: [validationMixin],
    validations: {
        name_cn: { required, maxLength: maxLength(20) },
        name_en: { required, maxLength: maxLength(20) },
        // desc: { required, maxLength: maxLength(20) },
    },

    data: function () {
        return {
            rid: "",
            name_cn: "",
            name_en: "",
            res_type: "",
            ext: "",
            category: 0,
            group_ids: "",
            group_index: 0,

            select: {},
            items: [
                { name: '人物', category: 0 },
                { name: '动物', category: 1 },
                { name: '植物', category: 7 },
                { name: '食物', category: 8 },
                { name: '道具', category: 2 },
                { name: '字母', category: 9 },
                { name: '背景（竖屏）', category: 3 },
                { name: '背景（横屏）', category: 4 },
            ],

            dialog: false,
            isCreate: false,
          
            previewImg: "",
            file: null,

            rules: [
                value => !value || value.size < 2000000 || '图片大小要求小于2MB',
            ],
        };
    },
    computed: {
        nameCnErrors() {
            const errors = [];
            if (!this.$v.name_cn.$dirty) return errors;
            !this.$v.name_cn.maxLength &&
                errors.push("不能超过" + this.$v.name_cn.maxLength + "个字符");
            !this.$v.name_cn.required && errors.push("必须填写中文名称");
            return errors;
        },
        nameEnErrors() {
            const errors = [];
            if (!this.$v.name_en.$dirty) return errors;
            !this.$v.name_en.required && errors.push("必须填写英文名称");
            return errors;
        },
    },
    created() {},
    activated() {},
    watch: {},
    methods: {
        openDlg(item = null, category = "0") {
            if (item) {
                this.rid = item.rid
                this.name_cn = item.name_cn
                this.name_en = item.name_en
                this.res_type = item.res_type
                this.ext = item.ext
                this.group_ids = item.group_ids
                this.group_index = item.group_index
                this.previewImg = getImgUrl2(item.rid)

                this.isCreate = false

            } else {
                this.clear()
                this.isCreate = true
            }

            // 默认图片
            if (this.previewImg == "") {
                this.previewImg = require('@/assets/images/admin/default-group.png')
            }

            // 设置分类
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].category == category) {
                    this.select = this.items[i]
                    break
                }
            }
            this.category = category

            this.dialog = true
        },
        clear() {
            this.$v.$reset()
            
            this.rid = ""
            this.name_cn = ""
            this.name_en = ""
            this.res_type = ""
            this.ext = ""
            this.category = ""
            this.group_ids = ""
            this.group_index = 0
            this.previewImg = ""
            this.select = [],
            this.file = null
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.isCreate) {
                    if (this.file) {
                        // 上传到七牛
                        await qiniu.uploadFile(this.file, 'images', this.saveFileInfo)
                        
                    } else {
                        // TODO: 增加验证提示
                        console.log("请先上传图片")
                    }
                } else {
                    this.saveFileInfo(this.rid)
                }
            }
        },
        // 上传完成后回调：
        saveFileInfo(newId) {
            // 保存新图片id
            this.rid = newId

            // 用户确认后调用submit回调，将新的参数传递出去
            this.$emit("submit", {
                isCreate: this.isCreate,
                rid: this.rid,
                name_cn: this.name_cn,
                name_en: this.name_en,
                res_type: this.res_type,
                ext: this.ext,
                category: this.category,
                group_ids: this.group_ids,
                group_index: this.group_index
            })
            this.dialog = false
        },
        // 用户选择图片后触发
        uploadFile() {
            if (!this.file) {
                return
            }
            if (this.file) {
                // console.log(this.file)
                // 获取图片类型
                this.res_type = "img"
                this.name_cn = this.file.name.split(".")[0]
                this.name_en = this.file.name.split(".")[0]
                this.ext = this.file.name.split(".")[1]
                // 展示预览图
                this.previewImg = URL.createObjectURL(this.file)
            }
        },
        selected(event) {
            // 获取下标
            this.category = event.category
        }
    },
    components: {},
};
</script>
